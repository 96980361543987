import * as React from "react";
import Login from "./auth/Login";
import { auth } from "./data/fire";
import { onAuthStateChanged } from "firebase/auth";
import { HashRouter, Route, Routes } from "react-router-dom";
// Auth
import Layout from "./layouts/Layout";
import LayoutSplash from "./layouts/LayoutSplash";

//Escritorio
import Splash2 from "./paginas/Admin/Esc/Splash2";
// Error404
import Error404 from "./Error404";

// Siniestralidad
// 2023
import Sintijuana2023 from "./paginas/Admin/Sin/2023/Sintijuana2023";
import Sinhermosillo2023 from "./paginas/Admin/Sin/2023/Sinhermosillo2023";
import Sinreynosa2023 from "./paginas/Admin/Sin/2023/Sinreynosa2023";
import Sinsaltillo2023 from "./paginas/Admin/Sin/2023/Sinsaltillo2023";
import Sinmazatlan2023 from "./paginas/Admin/Sin/2023/Sinmazatlan2023";
import Sinqueretaro2023 from "./paginas/Admin/Sin/2023/Sinqueretaro2023";
import Sincuenavaca2023 from "./paginas/Admin/Sin/2023/Sincuenavaca2023";
import Sinpuebla2023 from "./paginas/Admin/Sin/2023/Sinpuebla2023";
import Sinveracruz2023 from "./paginas/Admin/Sin/2023/Sinveracruz2023";
import Sincoatzacoalcos2023 from "./paginas/Admin/Sin/2023/Sincoatzacoalcos2023";
import Sinedomex2023 from "./paginas/Admin/Sin/2023/Sinedomex2023";
import Sinvillahermosa2023 from "./paginas/Admin/Sin/2023/Sinvillahermosa2023";
import Sinoaxaca2023 from "./paginas/Admin/Sin/2023/Sinoaxaca2023";

// 2023
import Sintijuana2024 from "./paginas/Admin/Sin/2024/Sintijuana2024";
import Sinhermosillo2024 from "./paginas/Admin/Sin/2024/Sinhermosillo2024";
import Sinreynosa2024 from "./paginas/Admin/Sin/2024/Sinreynosa2024";
import Sinsaltillo2024 from "./paginas/Admin/Sin/2024/Sinsaltillo2024";
import Sinmazatlan2024 from "./paginas/Admin/Sin/2024/Sinmazatlan2024";
import Sinqueretaro2024 from "./paginas/Admin/Sin/2024/Sinqueretaro2024";
import Sincuenavaca2024 from "./paginas/Admin/Sin/2024/Sincuernavaca2024";
import Sinpuebla2024 from "./paginas/Admin/Sin/2024/Sinpuebla2024";
import Sinveracruz2024 from "./paginas/Admin/Sin/2024/Sinveracruz2024";
import Sincoatzacoalcos2024 from "./paginas/Admin/Sin/2024/Sincoatzacoalcos2024";
import Sinedomex2024 from "./paginas/Admin/Sin/2024/Sinedomex2024";
import Sinvillahermosa2024 from "./paginas/Admin/Sin/2024/Sinvillahermosa2024";
import Sinoaxaca2024 from "./paginas/Admin/Sin/2024/Sinoaxaca2024";

// Control de facturas
import Controlfact2022 from "./paginas/Admin/Ctr/Controlfact2022";
import Controlfact2224 from "./paginas/Admin/Ctr/Controlfact2224";
import Controlsinmill from "./paginas/Admin/Ctr/Controlsinmill";
// estadisticas
import Controlfacturas from "./paginas/Admin/Est/Controlfacturas";
import Analisis from "./paginas/Admin/Est/Analisis";
import Segcontatistas from "./paginas/Admin/Est/Segcontatistas";
import Pagoequipos from "./paginas/Admin/Est/Pagoequipos";
import Electronicos from "./paginas/Admin/Est/Electronicos";
import Sesionesquincenales from "./paginas/Admin/Est/Sesionesquincenales";
import Reclamadocapufe from "./paginas/Admin/Est/Reclamadocapufe";
import Formalreclamacion from "./paginas/Admin/Est/Formalreclamacion";
import Torrecontrol from "./paginas/Admin/Est/Torrecontrol";
import Siniestrosespeciales from "./paginas/Admin/Est/Siniestrosespeciales";

//Rentabilidad
import Analisissin from "./paginas/Admin/Ren/Analisissin";
import Aforo from "./paginas/Admin/Ren/Aforo";
//
import Cotizador from "./paginas/Admin/Cot/Cotizador";
//
import Capacitacion from "./paginas/Admin/Cap/Capacitacion";

// Configuracion

function App() {
  const [permisos, setPermisos] = React.useState([]);
  const [usuario, setUsuario] = React.useState(null);
  const [authState, setAuthState] = React.useState(null);
  const [autusuario, setAutUsuario] = React.useState([]);
  const perfil = [
    {
      email: "",
    },
  ];
  React.useEffect(() => {
    // consulta si existe algun  nuevo  usuario 

    // actualiza la tabla de usuarios

    //
    const unSuscribirAuth = onAuthStateChanged(
      auth,
      async (authenticatedUser) => {
        if (authenticatedUser) {
          setAutUsuario(authenticatedUser);
          setUsuario(authenticatedUser);
          perfil.email = authenticatedUser.email;
          setAuthState("Dashboard");
        } else {
          setUsuario(null);
          setAuthState("login");
        }
      }
    );

    return unSuscribirAuth;
  }, [usuario]);

  if (authState === null)
    return (
      <div className="font-bold text-center text-5xl py-[15vh]">
        Cargando...
      </div>
    );
  if (authState === "login")
    return <Login setAuthState={setAuthState} autusuario={autusuario} />;
  let nick = 'ninguno'
  switch (autusuario.email) {
    //Admin 
    case 'paola.perez@serviciosgob.mx':
      nick = 'admin'
      break;
    case 'carlos.arreola@fyfasesores.mx':
      nick = 'admin'
      break;
    case 'mauricio.valdez@sinestry.com':
      nick = 'admin'
      break;
    // DIRECTOR GNP
    case 'director@gnp.sinestryapp.com':
      nick = 'directorgnp'
      break;
    case 'miguelangel.aguilar@gnp.com.mx':
      nick = 'directorgnp'
      break;
    case 'reservas@gnp-capufe.sinestryapp.com':
      nick = 'directorgnp'
      break;
    // COORDINADOR GNP
    case 'rafael.magdaleno@gnp.com.mx':
      nick = 'coordinadorgnp'
      break;
    case 'operador@gnp.sinestryapp.com':
      nick = 'coordinadorgnp'
      break;
    case 'ana.platon@gnp.com.mx':
      nick = 'coordinadorgnp'
      break;
    // GERENTES GNP
    case 'innovacion@sinestry.com':
      nick = 'gerentegnp'
      break;
    case 'paola.perez@sinestry.com':
      nick = 'gerentegnp'
      break;
    case 'mauricio.valdez@serviciosgob.mx':
      nick = 'gerentegnp'
      break;
    case 'gerente@gnp.sinestryapp.com':
      nick = 'gerentegnp'
      break;
    case 'diana.lara@gnp.com.mx':
      nick = 'gerentegnp'
      break;
    case 'emma.ley@gnp.com.mx':
      nick = 'gerentegnp'
      break;
    // DIRECTORES SINESTRY
    case 'director@sinestryapp.com':
      nick = 'directorsinestry'
      break;
    case 'pedro.vergara@sinestry.com':
      nick = 'directorsinestry'
      break;
    case 'pedro.vergara@serviciosgob.mx':
      nick = 'directorsinestry'
      break;
    case 'pedro.vergara@fyfasesores.mx':
      nick = 'directorsinestry'
      break;
    case 'francisco.vergara@sinestry.com':
      nick = 'directorsinestry'
      break;
    case 'francisco.vergara@serviciosgob.mx':
      nick = 'directorsinestry'
      break;
    case 'francisco.vergara@fyfasesores.mx':
      nick = 'directorsinestry'
      break;
    case 'gonzalo.bravo@sinestry.com':
      nick = 'directorsinestry'
      break;
    case 'gonzalo.bravo@serviciosgob.mx':
      nick = 'directorsinestry'
      break;
    case 'gonzalo.bravo@fyfasesores':
      nick = 'directorsinestry'
      break;
    // OPERATIVO SINESTRY
    case 'operador@sinestryapp.com':
      nick = 'operativosinestry'
      break;
    case 'carina.luna@sinestry.com':
      nick = 'operativosinestry'
      break;
    case 'manuel.mendoza@sinestry.com':
      nick = 'operativosinestry'
      break;
    case 'miriam.zuarez@sinestry.com':
      nick = 'operativosinestry'
      break;
    case 'david.sotelo@serviciosgob.mx':
      nick = 'operativosinestry'
      break;
    // GERENTE SINESTRY
    case 'yessenia.luna@sinestry.com':
      nick = 'coordinadorsinestry'
      break;
    case 'yessenia.luna@serviciosgob.mx':
      nick = 'coordinadorsinestry'
      break;
    case 'hector.trujillo@serviciosgob.mx':
      nick = 'coordinadorsinestry'
      break;
    // ninguno
    default:
      nick = 'ninguno'
      break;
  }

  if (usuario)
    return (
      <div>
        <HashRouter>
          <Routes>
            <Route>
              <Route path="/" element={<LayoutSplash autusuario={autusuario} />}>
                <Route index elemnt={<Splash2 autusuario={autusuario} />} />
              </Route>
              <Route path="/" element={<Layout autusuario={autusuario} />}>
                <Route index elemnt={<Splash2 autusuario={autusuario} />} />
                {/* 2023 */}
                <Route path="/sin/2023/tijuana" element={<Sintijuana2023 />} />
                <Route
                  path="/sin/2023/hermosillo"
                  element={<Sinhermosillo2023 />}
                />
                <Route path="/sin/2023/reynosa" element={<Sinreynosa2023 />} />
                <Route
                  path="/sin/2023/saltillo"
                  element={<Sinsaltillo2023 />}
                />
                <Route
                  path="/sin/2023/mazatlan"
                  element={<Sinmazatlan2023 />}
                />
                <Route
                  path="/sin/2023/queretaro"
                  element={<Sinqueretaro2023 />}
                />
                <Route
                  path="/sin/2023/cuernavaca"
                  element={<Sincuenavaca2023 />}
                />
                <Route path="/sin/2023/puebla" element={<Sinpuebla2023 />} />
                <Route
                  path="/sin/2023/veracruz"
                  element={<Sinveracruz2023 />}
                />
                <Route
                  path="/sin/2023/coatzacoalcos"
                  element={<Sincoatzacoalcos2023 />}
                />
                <Route path="/sin/2023/edomex" element={<Sinedomex2023 />} />
                <Route
                  path="/sin/2023/villahermosa"
                  element={<Sinvillahermosa2023 />}
                />
                <Route
                  path="/sin/2023/oaxaca"
                  from
                  element={<Sinoaxaca2023 />}
                />
                {/* 2024 */}
                <Route path="/sin/2024/tijuana" element={<Sintijuana2024 />} />
                <Route
                  path="/sin/2024/hermosillo"
                  element={<Sinhermosillo2024 />}
                />
                <Route path="/sin/2024/reynosa" element={<Sinreynosa2024 />} />
                <Route
                  path="/sin/2024/saltillo"
                  element={<Sinsaltillo2024 />}
                />
                <Route
                  path="/sin/2024/mazatlan"
                  element={<Sinmazatlan2024 />}
                />
                <Route
                  path="/sin/2024/queretaro"
                  element={<Sinqueretaro2024 />}
                />
                <Route
                  path="/sin/2024/cuernavaca"
                  element={<Sincuenavaca2024 />}
                />
                <Route path="/sin/2024/puebla" element={<Sinpuebla2024 />} />
                <Route
                  path="/sin/2024/veracruz"
                  element={<Sinveracruz2024 />}
                />
                <Route
                  path="/sin/2024/coatzacoalcos"
                  element={<Sincoatzacoalcos2024 />}
                />
                <Route path="/sin/2024/edomex" element={<Sinedomex2024 />} />
                <Route
                  path="/sin/2024/villahermosa"
                  element={<Sinvillahermosa2024 />}
                />
                <Route
                  path="/sin/2024/oaxaca"
                  from
                  element={<Sinoaxaca2024 />}
                />
                {/* control de facturas  */}
                <Route
                  path="/ctf/controlfact2022"
                  from
                  element={<Controlfact2022 />}
                />
                <Route
                  path="/ctf/Controlfact2224"
                  element={<Controlfact2224 />}
                />
                <Route
                  path="/ctf/controlsinmill"
                  element={<Controlsinmill />}
                />
                {/* Estaditicas */}
                <Route
                  path="/est/controlfacturas"
                  element={<Controlfacturas />}
                />
                <Route
                  path="/est/segcontatistas"
                  element={<Segcontatistas />}
                />
                <Route path="/est/analisis" element={<Analisis />} />
                <Route path="/est/pagoequipos" element={<Pagoequipos />} />
                <Route path="/est/electronicos" element={<Electronicos />} />
                <Route
                  path="/est/sesionesquincenales"
                  element={<Sesionesquincenales />}
                />
                <Route
                  path="/est/reclamadoscapufe"
                  element={<Reclamadocapufe />}
                />
                <Route
                  path="/est/formalreclamacion"
                  element={<Formalreclamacion />}
                />
                <Route
                  path="/est/siniestrosespeciales"
                  element={<Siniestrosespeciales />}
                />
                <Route path="/est/torrecontrol" element={<Torrecontrol />} />
                <Route
                  path="/est/controlfacturas"
                  element={<Controlfacturas />}
                />

                {/* Rentabilidad */}
                <Route path="/ren/analisissin" from element={<Analisissin />} />
                <Route path="/ren/aforo" element={<Aforo />} />
                {/* cotizador */}
                <Route path="/cot/cotizador" from element={<Cotizador />} />
                {/* cotizador */}
                <Route path="/cap/capacitacion" from element={<Capacitacion />} />
              </Route>
            </Route>
            {/* Errores */}
            <Route path="*" element={<Error404 />}></Route>
          </Routes>
        </HashRouter>
      </div>
    );
}

export default App;

import { React } from "react";

        const Capacitacion = ({ nombre, mdstatus, eseditable }) => {
            return(
                <div className=" w-full h-full">
                <iframe className=" w-full h-full border-0"
                src="https://drive.google.com/drive/folders/1UmCM6v_z7zoPFAtNH69yZwi6PnARSXZh?usp=drive_link" 
                allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe></div>
            )
        }
        export default Capacitacion;